<template>
  <div class="card bg-light shadow mt-3">
    <div class="card-body">
      <p>Fecha</p>
      <input type="date" v-model="nuevoIngreso.fecha" class="form-control">
      <p>Usuario</p>
      <Multiselect
        v-model="nuevoIngreso.usuario"
        :close-on-select="true"
        :options="options"
        @search-change="searchUsuario"
        placeholder="Busca un usuario"
        :internal-search="false"
        :clear-on-select="true"
        :custom-label="userWithFullName"
      >
      </Multiselect>
      <p>Tipo</p>
      <select class="form-control" v-model="nuevoIngreso.tipo">
        <option value="bizum">Bizum</option>
        <option value="transferencia">Transferencia</option>
        <option value="metalico">Metálico</option>
      </select>
      <p>Cantidad</p>
      <input type="number" v-model="nuevoIngreso.cantidad" class="form-control">
      <p>Notificar</p>
      <input type="checkbox" v-model="nuevoIngreso.notificar" class="form-control">
      <p>Sumar días</p>
      <input type="checkbox" v-model="nuevoIngreso.sumarDias" class="form-control">

      <div class="text-center m-2">
        <LoadingButton @clicked="crearIngreso" :iconProp="'fas fa-paper-plane'" :loadingProp="loadingNuevo" :classProp="'btn btn-outline-info'" :textProp="'Crear'" class="m-1"></LoadingButton>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import config from '../config'
  import LoadingButton from '../components/LoadingButton.vue'
  import Multiselect from 'vue-multiselect'
  import Swal from 'sweetalert2'

  export default {
    data() {
      return {
        loadingNuevo: false,
        options: [],
        nuevoIngreso: {
          fecha: new Date().toISOString().slice(0,10),
          usuario: null,
          tipo: null,
          cantidad: null,
          notificar: true,
          sumarDias: true
        }
      }
    },
    components: {
      LoadingButton,
      Multiselect
    },
    methods: {
      async searchUsuario(query) {
        try {
          if (!query) {
            return
          }

          const res = await axios.get(config.apiUrl + '/usuarios/buscar?query=' + query)

          this.options = res.data
        } catch (e) {
          console.log(e)
        }
      },
      userWithFullName(option) {
        return `${option.usuario} - ${option.nombreCompleto}`
      },
      async crearIngreso() {
        try {
          this.loadingNuevo = true

          this.nuevoIngreso.usuario = this.nuevoIngreso.usuario.usuario

          this.nuevoIngreso.cantidad = Number(this.nuevoIngreso.cantidad)

          const res = await axios.post(config.apiUrl + '/ingresos/nuevo', this.nuevoIngreso)
          this.loadingNuevo = false

          Swal.fire({
            title: 'Bien',
            text: res.data,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })

          this.$emit('nuevo-ingreso')
        } catch (e) {
          this.loadingNuevo = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
