<template>
  <div>
    <h1 class="mt-4 mb-4">Ingresos</h1>

    <div class="row">
      <div class="col-sm-auto">
        <button type="button" @click="showNuevoIngreso = !showNuevoIngreso" class="btn m-1 btn-outline-primary"><i class="fas fa-plus"></i> Nuevo</button>
      </div>

      <div class="col-sm-auto">
        <Multiselect
          v-model="selectedOption"
          :close-on-select="true"
          :options="options"
          @search-change="searchUsuario"
          placeholder="Busca un usuario"
          :internal-search="false"
          :clear-on-select="true"
          :custom-label="userWithFullName"
          @input="getUserIngresos()"
        >
        </Multiselect>
      </div>

      <div class="col-sm-auto">
        <button type="button" @click="getIngresos" class="btn btn-outline-info"><i class="fas fa-redo-alt"></i></button>
      </div>

    </div>

    <NuevoIngreso v-show="showNuevoIngreso" @nuevo-ingreso="nuevoIngreso"></NuevoIngreso>

    <p v-show="errorIngresos">Error obteniendo los ingresos</p>

    <h3 v-show="loadingIngresos" class="text-center"><i class="fas fa-circle-notch fa-spin"></i></h3>

    <div v-if="!errorIngresos && !loadingIngresos" class="text-center">
      <p class="mt-3">Últimos <strong>{{ ingresos.length }}</strong> ingreso/s</p>

      <div v-for="ingreso in ingresos" :key="ingreso._id" class="row m-3 shadow text-center align-items-center border bg-light">
        <div class="col-sm">
          <div class="card-body">
            <img src="../assets/images/user.png" class="card-img-top img-fluid rounded-circle" style="max-width: 50px">
            <h5>{{ ingreso.usuario }}</h5>
          </div>
        </div>

        <div class="col-sm">
          <div class="card-body">
            <h5><i class="fas fa-calendar-day text-primary"></i> {{ new Date(ingreso.fecha).toLocaleDateString() }}</h5>
          </div>
        </div>

        <div class="col-sm">
          <div class="card-body">
            <h5 class="text-capitalize"><i class="fas fa-money-check-alt text-success"></i> {{ ingreso.tipo }}</h5>
          </div>
        </div>

        <div class="col-sm">
          <div class="card-body">
            <h5><i class="fas fa-coins text-warning"></i> {{ ingreso.cantidad }} €</h5>
          </div>
        </div>

        <div class="col-sm">
          <div class="card-body">
            <LoadingButton @clicked="eliminarIngreso(ingreso._id)" :iconProp="'fas fa-trash-alt'" :loadingProp="loadingEliminarIngreso" :classProp="'btn btn-danger'"></LoadingButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import config from '../config'
  import NuevoIngreso from '../components/NuevoIngreso.vue'
  import LoadingButton from '../components/LoadingButton.vue'
  import Multiselect from 'vue-multiselect'
  import Swal from 'sweetalert2'

  export default {
    data() {
      return {
        ingresos: [],
        loadingIngresos: true,
        loadingEliminarIngreso: false,
        errorIngresos: false,
        showNuevoIngreso: false,
        options: [],
        selectedOption: null
      }
    },
    components: {
      NuevoIngreso,
      LoadingButton,
      Multiselect
    },
    methods: {
      async getIngresos() {
        try {
          this.loadingIngresos = true
          const res = await axios.get(config.apiUrl + '/ingresos')
          this.loadingIngresos = false
          this.ingresos = res.data.splice(0, 10)
        } catch (e) {
          this.loadingIngresos = false

          this.errorIngresos = e.response.data
        }
      },
      async getUserIngresos() {
        try {
          this.loadingIngresos = true
          const res = await axios.get(config.apiUrl + '/ingresos?usuario=' + this.selectedOption.usuario)
          this.loadingIngresos = false
          this.ingresos = res.data
        } catch (e) {
          this.loadingIngresos = false

          this.errorIngresos = e.response.data
        }
      },
      async eliminarIngreso(id) {
        try {
          this.loadingEliminarIngreso = true
          const res = await axios.post(config.apiUrl + '/ingresos/eliminar', {
            id
          })
          this.loadingEliminarIngreso = false

          Swal.fire({
            title: 'Bien',
            text: res.data,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })

          this.getIngresos()
        } catch (e) {
          this.loadingEliminarIngreso = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      },
      nuevoIngreso() {
        this.showNuevoIngreso = false
        this.getIngresos()
      },
      async searchUsuario(query) {
        try {
          if (!query) {
            return
          }

          const res = await axios.get(config.apiUrl + '/usuarios/buscar?query=' + query)

          this.options = res.data
        } catch (e) {
          console.log(e)
        }
      },
      userWithFullName(option) {
        return `${option.usuario} - ${option.nombreCompleto}`
      }
    },
    mounted() {
      this.$store.commit('setPanelLayout')
      this.getIngresos()
    }
  }
</script>
